<template>
  <v-container fluid>
    <v-row>
      <v-col cols="0" md="4"></v-col>
      <v-col cols="12" md="4">
        <div class="text-center text-md-left"><v-btn color="success" class="mb-2" @click="addBtnClicked"><v-icon>mdi-plus</v-icon> Add {{ name }}</v-btn></div>

        <div v-if="listTypeOptions">
          <v-select
            v-model="listTypeSelected"
            :items="listTypeOptions"
            label="List Type"
            @change="getItems"
          ></v-select>
        </div>

        <component :is="tileType" v-for="item in items" :key="item.id" :item="item" @editBtnClicked="editBtnClicked" @deleteBtnClicked="deleteBtnClicked"></component>
      </v-col>
      <v-col cols="0" md="4"></v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from "axios";
  import { EventBus } from "../../../events/EventBus.js";

  import TVListTile from "../EditDeleteTiles/TV/ListTile.vue";
  import MovieMovieTile from "../EditDeleteTiles/movies/MovieTile.vue";
  import MovieListTile from "../EditDeleteTiles/movies/ListTile.vue";
  import GameGameTile from "../EditDeleteTiles/games/GameTile.vue";
  import GameListTile from "../EditDeleteTiles/games/ListTile.vue";
  import ServiceTile from "../EditDeleteTiles/ServiceTile.vue";

  export default {
    name: "ShowEditDeleteTiles",
    components: { TVListTile, MovieMovieTile, MovieListTile, GameListTile, GameGameTile, ServiceTile },
    props: [ "apiId", "name", "itemId", "tileType", "listTypeOptions", "dialogId" ],
    data: function() {
      return {
        listTypeSelected: null,
        items: null,
        itemEditing: null
      };
    },
    methods: {
      getItems: function() {
        this.items = null;
        let self = this;
        this.$http.get("" + this.apiId, { params: { listtype: this.listTypeSelected, bust: Math.random() }}).then(function(response) {
          // handle success
          self.items = response.data.items;
        });
      },
      addBtnClicked: function() {
        this.itemEditing = null;
        //EventBus.$emit("open-dialog", {id: this.dialogId, return: this.dialogId + "Added"});
        console.log("Opening from here", this.dialogId);
        EventBus.$emit("open-dialog", {id: this.dialogId, return: null, editingId: null});
      },
      editBtnClicked: function(d) {
        this.itemEditing = d[this.itemId];
        //EventBus.$emit("open-dialog", {id: this.dialogId, return: this.dialogId + "Edited", data: d});
        EventBus.$emit("open-dialog", {id: this.dialogId, return: null, data: d, editingId: this.itemEditing});
      },
      deleteBtnClicked: function(d) {
        if(confirm("Really delete " + this.name + " \"" + d.name + "\"?")) {
          let self = this;
          
          this.$http.delete(this.apiId + "/" + d[this.itemId]).then(function(response) {
            if(response.data.success) {
                self.getItems();
            }
          });
        }
      }
    },
    mounted: function() {
      console.log("Edit Delete Tiles");
      
      EventBus.$on([this.dialogId + "Added", this.dialogId + "Edited"], data => {
        console.log("Calling upsert from here");
        let self = this;
        this.$http.post("" + this.apiId + "/upsert", {id: this.itemEditing, data: data.formData}).then(function(response) {

         if(response.data.success) {
            EventBus.$emit("close-dialog", {id: self.dialogId});
            self.getItems();
         }
        });
      });

      console.log(this.dialogId + "RefreshData" + " - created");

      EventBus.$on([this.dialogId + "RefreshData"], data => {
        console.log("Gets to refresh emit");
        this.getItems();
      });

      this.getItems();
    },
    beforeDestroy() {  
      EventBus.$off([this.dialogId + "Added", this.dialogId + "Edited"]);
      EventBus.$off([this.dialogId + "RefreshData"]);
    }
  };
</script>
