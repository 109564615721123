<template>
  <div>
    <v-container fluid>
    <v-row class="my-4">
      <v-col cols="0" md="3" lg="4"></v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          label="Maximum Active Shows (per list)"
          v-model="maxShows"
          required
        ></v-text-field>

        <v-text-field
          label="Binge chances (1 in x)"
          v-model="bingeChances"
          required
        ></v-text-field>

        <v-text-field
          label="New show chances (1 in x)"
          v-model="newShowChances"
          required
        ></v-text-field>

        <v-btn color="primary" text @click="formSubmitted()">Save</v-btn> <span v-html="msg"></span>
      </v-col>
      <v-col cols="0" md="3" lg="4"></v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    name: "SettingsAutoServices",
    data: function() {
      return {
        maxShows: 999,
        bingeChances: 4,
        newShowChances: 3,
        msg: ""
      }
    },
    computed: {
      formData: function() {
        return {
          max_shows: this.maxShows,
          binge_chances: this.bingeChances,
          new_show_chances: this.newShowChances
        }
      }
    },
    methods: {
      formSubmitted: function() {
        let self = this;
        this.$http.post("settings/auto/update", {data: this.formData}).then(function(response) {

          if(response.data.success) {
            self.msg = "<span style='color: green'>Saved</span>";
          }
        });
      }
    },
    mounted: function() {
      let self = this;
      this.$http
        .get("settings/auto")
        .then(function(response) {
          // handle success
          self.maxShows = response.data.data.max_shows;
          self.bingeChances = response.data.data.binge_chances;
          self.newShowChances = response.data.data.new_show_chances;
        });
    }
  };
</script>
