<template>
  <div>
    <v-app-bar app color="chrome" class="pr-md-10">
      <v-toolbar-title><v-btn to="/" text>TV Schedule</v-btn></v-toolbar-title>
      <v-spacer />

      <!-- TV -->
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon color="navBtn" v-on="on">
            <v-icon>mdi-television</v-icon>
          </v-btn>
        </template>

        <v-list>
          <ListItemDialogButton
            text="Schedule"
            icon="timeline-text"
            routeId="scheduleSummaryTab"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="List"
            icon="format-list-bulleted"
            routeId="showLists"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Watch History"
            icon="history"
            routeId="watchHistory"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Tools"
            icon="wrench"
            routeId="tools"
          ></ListItemDialogButton>
        </v-list>
      </v-menu>

      <!-- Movies -->
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon color="navBtn" v-on="on">
            <v-icon>mdi-movie-open</v-icon>
          </v-btn>
        </template>

        <v-list>
          <ListItemDialogButton
            text="Movies"
            icon="format-list-bulleted"
            routeId="MoviesActiveTab"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="List"
            icon="controller"
            routeId="moviesLists"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Tools"
            icon="wrench"
            routeId="movieTools"
          ></ListItemDialogButton>
        </v-list>
      </v-menu>
      <!-- <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon color="navBtn" v-on="on">
            <v-icon>mdi-movie-open</v-icon>
          </v-btn>
        </template>

        <v-list>
          <ListItemDialogButton
            text="Movies"
            icon="filmstrip-box"
            routeId="MoviesSummaryTab"
          ></ListItemDialogButton>
        </v-list>
      </v-menu> -->

      <!-- Games -->
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon color="navBtn" v-on="on">
            <v-icon>mdi-gamepad</v-icon>
          </v-btn>
        </template>

        <v-list>
          <ListItemDialogButton
            text="Games"
            icon="format-list-bulleted"
            routeId="GameActiveTab"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="List"
            icon="controller"
            routeId="gameLists"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Tools"
            icon="wrench"
            routeId="gameTools"
          ></ListItemDialogButton>
        </v-list>
      </v-menu>

      <!-- Settings -->
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon color="navBtn" v-on="on">
            <v-icon>mdi-application-cog</v-icon>
          </v-btn>
        </template>

        <v-list>
          <ListItemDialogButton
            text="Services"
            icon="video-account"
            routeId="showServices"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Settings"
            icon="cog"
            routeId="settings"
          ></ListItemDialogButton>
        </v-list>
      </v-menu>

      <!-- Add -->
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon color="navBtn" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>

        <v-list>
          <ListItemDialogButton
            text="Show"
            icon="television"
            dialogId="addShow"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Movie"
            icon="filmstrip"
            dialogId="addMovie"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Game"
            icon="gamepad-variant-outline"
            dialogId="addGame"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="List"
            icon="format-list-bulleted"
            dialogId="addList"
          ></ListItemDialogButton>
          <ListItemDialogButton
            text="Service"
            icon="video-account"
            dialogId="addService"
          ></ListItemDialogButton>
        </v-list>
      </v-menu>
    </v-app-bar>

    <Dialogs></Dialogs>
  </div>
</template>

<script>
  import ListItemDialogButton from "../buttons/ListItemDialogButton.vue";
  import Dialogs from "../layout/Dialogs.vue";

  export default {
    name: "TopNav",
    components: {
      ListItemDialogButton,
      Dialogs
    },
    data: function() {
      return {
        drawer: false
      };
    }
  };
</script>
