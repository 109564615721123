<template>
    <v-card class="mx-auto my-2">
      <v-card-title class="d-block headline text-center text-md-left">{{ item.name }}</v-card-title>
      <v-card-subtitle class="text-center text-md-left">{{ item.gameCount }} games</v-card-subtitle>
      <v-card-actions class="d-block text-center text-md-left">
        <v-btn
          text
          color="cyan"
          @click="editBtnClicked(item)"
        >
          Edit
        </v-btn>
        <v-btn
          text
          color="cyan"
          @click="deleteBtnClicked(item)"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
  export default {
    name: "ShowEditDeleteListTile",
    props: [ "item" ],
    data: function() {
        return {
        }
    },
    methods: {
        editBtnClicked: function(d) {
            this.$emit("editBtnClicked", d);
        },
        deleteBtnClicked: function(d) {
            this.$emit("deleteBtnClicked", d);
        }
    }
  };
</script>