var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"pr-md-10",attrs:{"app":"","color":"chrome"}},[_c('v-toolbar-title',[_c('v-btn',{attrs:{"to":"/","text":""}},[_vm._v("TV Schedule")])],1),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"navBtn"}},on),[_c('v-icon',[_vm._v("mdi-television")])],1)]}}])},[_c('v-list',[_c('ListItemDialogButton',{attrs:{"text":"Schedule","icon":"timeline-text","routeId":"scheduleSummaryTab"}}),_c('ListItemDialogButton',{attrs:{"text":"List","icon":"format-list-bulleted","routeId":"showLists"}}),_c('ListItemDialogButton',{attrs:{"text":"Watch History","icon":"history","routeId":"watchHistory"}}),_c('ListItemDialogButton',{attrs:{"text":"Tools","icon":"wrench","routeId":"tools"}})],1)],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"navBtn"}},on),[_c('v-icon',[_vm._v("mdi-movie-open")])],1)]}}])},[_c('v-list',[_c('ListItemDialogButton',{attrs:{"text":"Movies","icon":"format-list-bulleted","routeId":"MoviesActiveTab"}}),_c('ListItemDialogButton',{attrs:{"text":"List","icon":"controller","routeId":"moviesLists"}}),_c('ListItemDialogButton',{attrs:{"text":"Tools","icon":"wrench","routeId":"movieTools"}})],1)],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"navBtn"}},on),[_c('v-icon',[_vm._v("mdi-gamepad")])],1)]}}])},[_c('v-list',[_c('ListItemDialogButton',{attrs:{"text":"Games","icon":"format-list-bulleted","routeId":"GameActiveTab"}}),_c('ListItemDialogButton',{attrs:{"text":"List","icon":"controller","routeId":"gameLists"}}),_c('ListItemDialogButton',{attrs:{"text":"Tools","icon":"wrench","routeId":"gameTools"}})],1)],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"navBtn"}},on),[_c('v-icon',[_vm._v("mdi-application-cog")])],1)]}}])},[_c('v-list',[_c('ListItemDialogButton',{attrs:{"text":"Services","icon":"video-account","routeId":"showServices"}}),_c('ListItemDialogButton',{attrs:{"text":"Settings","icon":"cog","routeId":"settings"}})],1)],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"navBtn"}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('v-list',[_c('ListItemDialogButton',{attrs:{"text":"Show","icon":"television","dialogId":"addShow"}}),_c('ListItemDialogButton',{attrs:{"text":"Movie","icon":"filmstrip","dialogId":"addMovie"}}),_c('ListItemDialogButton',{attrs:{"text":"Game","icon":"gamepad-variant-outline","dialogId":"addGame"}}),_c('ListItemDialogButton',{attrs:{"text":"List","icon":"format-list-bulleted","dialogId":"addList"}}),_c('ListItemDialogButton',{attrs:{"text":"Service","icon":"video-account","dialogId":"addService"}})],1)],1)],1),_c('Dialogs')],1)}
var staticRenderFns = []

export { render, staticRenderFns }