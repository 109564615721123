<template>
  <v-container class="bg-surface-variant">
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="4"
      >
        <v-btn @click="runRandom()">Random Movie from</v-btn><v-select v-model="randomMovieList" :items="lists"></v-select>
      </v-col>
    </v-row>

    <v-card class="mt-5">
      <div class="pa-5">
        <pre>{{ output }}</pre>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "MovieTools",
    components: { },
    data: function() {
      return {
        output: null,
        lists: [],
        randomMovieList: null
      }
    },
    methods: {
      runRandom: function() {
        let that = this;
        this.output = "";

        this.$http
          .get("/movies/tools/movieRandom", { params: { list: this.randomMovieList, bust: Math.random() }})
          .then(function(response) {
            that.output = response.data;
          });
      }
    },
    mounted() {
      let self = this;
      let workingLists = ["all"];

      this.$http.get("movies/list").then(function(response) {
        // self.lists = response.data.items;

        for(let i in response.data.items) {
          workingLists.push(response.data.items[i].listid);
        }

        self.lists = workingLists;
      });
    },
  };
</script>
