import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    // icons: {
    //   iconfont: 'mdi',
    // },
    theme: {
      themes: {
        light: {
            chrome: colors.cyan.base,
            tileSkipped: colors.blueGrey.lighten3,
            tileActive: colors.cyan.lighten5,
            tileFlagged: colors.yellow.base,
            tile: colors.white,
            tileNote: colors.blueGrey.lighten4,
            modalTitleBar: colors.grey.lighten2,
            navBtn: colors.yellow.base,
            flagBtnColor: colors.yellow.darken4,
            cardActionBtnColor: colors.cyan.darken2
        },
        dark: {
            chrome: colors.cyan.darken4,
            tileSkipped: colors.blueGrey.darken3,
            tileActive: colors.cyan.darken4,
            tileFlagged: colors.yellow.darken4,
            tile: colors.grey.darken4,
            tileNote: colors.blueGrey.darken4,
            modalTitleBar: colors.grey.darken2,
            success: colors.green.darken3,
            navBtn: colors.yellow.darken2,
            flagBtnColor: colors.yellow.darken2,
            cardActionBtnColor: colors.cyan.darken2
        },
      },
    },
  });

