<template>
  <v-card class="mx-auto my-2" :color="cardColor" :loading="loading">
    <div class="overline pt-1 mx-2">
      <v-row>
        <v-col cols="12" sm="4" class="text-center text-sm-left pb-0 pb-sm-3">
          <DayNameConverter
            v-if="listtype == 'dayed'"
            :day="show.day"
          ></DayNameConverter>
          <div v-else-if="listtype == 'completed'">{{ show.listid }}</div>
          <div v-else>Non-Day</div>
        </v-col>
        <v-col cols="12" sm="8" class="text-center text-sm-right pt-0 pt-sm-3">
          <!-- <v-btn elevation="2" outlined x-small @click="watchShow(true)">Edit</v-btn> -->
          <v-btn
            v-if="listtype != 'completed'"
            elevation="2"
            outlined
            x-small
            :color="flaggedBtnColor"
            @click="handleFlagClick"
            ><v-icon>mdi-flag</v-icon></v-btn
          >
          <CardDialogButton
            text="Edit"
            dialogId="addShow"
            :data="show"
            class="ml-1"
          ></CardDialogButton>
          <v-btn
            elevation="2"
            class="ml-1"
            outlined
            x-small
            @click="handleDeleteClick"
            >Delete</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div v-if="hasAttributes" class="mx-2 text-center text-md-left">
      <v-chip
        v-if="show.active_auto"
        x-small
        class="mx-1"
        color="success"
        text-color="white"
        >Active</v-chip
      >
      <v-chip
        v-if="!show.active_auto"
        x-small
        class="mx-1"
        color="error"
        text-color="white"
        >Inactive</v-chip
      >
      <v-chip
        v-if="show.saveEpisodes"
        x-small
        class="mx-1"
        color="success"
        text-color="white"
        >Save</v-chip
      >
      <v-chip
        v-if="show.episodesExpire"
        x-small
        class="mx-1"
        color="success"
        text-color="white"
        >Expire</v-chip
      >
    </div>
    <v-card-title class="headline d-block text-center text-md-left">{{
      show.name
    }}</v-card-title>
    <v-card-subtitle class="text-center text-md-left">
      <div v-if="show.saveEpisodes">
        Previous Episode - {{ show.saveEpisodesEpisodeName }} (s{{ show.saveEpisodesSeason }}e{{ show.saveEpisodesEpisodeNumber }})
      </div>

      <div>{{ show.servicename }}</div>

      <div v-if="(listtype != 'dayed') && (listtype != 'completed')" class="my-1"></div>
      <div v-else-if="show.last_watch" class="my-1">
        <abbr title="Last Watched">LW</abbr>
        {{ lastWatchFormatted }}
      </div>
      <div v-else class="my-1">Never Watched</div>
      <!-- <v-card-subtitle>{{ show.skipped }}</v-card-subtitle> -->
      <div
        v-if="show.skipped != 0"
        v-html="skippedFormatted"
        class="my-1"
      ></div>

      <v-card
        v-if="show.notes"
        elevation="0"
        class="mt-5 pa-1"
        color="tileNote"
        style="white-space: pre;"
        v-html="show.notes"
      ></v-card>
    </v-card-subtitle>
    <v-card-actions
      v-if="listtype == 'dayed'"
      class="d-block text-center text-md-left"
    >
      <span v-if="show.active_auto">
        <v-btn elevation="2" outlined small class="ma-2" @click="watchShow(true)"
          >Watch</v-btn
        >
        <v-btn elevation="2" outlined small class="ma-2" @click="watchShow(false)"
          >Watch (don't count)</v-btn
        ><br />
        <v-btn
          v-if="show.active"
          elevation="2"
          outlined
          small
          class="ma-2"
          @click="skipShow()"
          >Skip</v-btn
        >
        <v-btn
          v-else
          elevation="2"
          outlined
          small
          class="ma-2"
          @click="makeCurrent()"
          >Make Current</v-btn
        >
        <br/>
        <v-btn elevation="2" outlined small class="ma-2" @click="deactivateDayed()">
          Deactivate
        </v-btn>
      </span>
      <span v-else>
        <v-btn elevation="2" outlined small class="ma-2" @click="activateDayed()">
          Activate
        </v-btn>
      </span>
      <v-btn elevation="2" outlined small class="ma-2" @click="makeComplete()"
        >Complete</v-btn
      >
    </v-card-actions>
    <v-card-actions v-else-if="listtype == 'completed'">
      <v-btn text @click="resumeShow()">Resume</v-btn>
    </v-card-actions>
    <v-card-actions v-else-if="listtype == 'noday' && nodaytype == 'waiting'">
      <v-btn text @click="startNoDayShow()">Start</v-btn>
      <v-btn text @click="makeComplete()">Complete</v-btn>
    </v-card-actions>
    <v-card-actions v-else-if="listtype == 'noday' && nodaytype == 'active'">
      <v-btn text @click="endNoDayShow()">End</v-btn>
      <v-btn text @click="makeComplete()">Complete</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

import DayNameConverter from "../../utility/DayNameConverter.vue";
import CardDialogButton from "../../buttons/CardDialogButton.vue";
import { EventBus } from "../../../events/EventBus.js";
import * as easings from "vuetify/es5/services/goto/easing-patterns";

export default {
  name: "ShowTile",
  components: { DayNameConverter, CardDialogButton },
  props: ["listtype", "nodaytype", "show", "skipped"],
  data: function() {
    return {
      activeColor: "tileActive",
      skippedColor: "tileSkipped",
      defaultColor: "tile",
      loading: "false",
      defaultLoadingColor: "primary"
    };
  },
  computed: {
    cardColor: function() {
      if (this.show.skipped < 0) {
        return this.skippedColor;
      } else if (this.show.active) {
        return this.activeColor;
      }

      return this.defaultColor;
    },
    loadingColor: function() {
      return this.defaultLoadingColor;
    },
    flaggedBtnColor: function() {
      return this.show.flagged ? "flagBtnColor" : "";
    },
    lastWatchFormatted: function() {
      var d = new Date(this.show.last_watch + " UTC+0000"); // Server sends/stores in UTC
      var now = new Date();

      /*
        console.log("D: " + d);
        console.log("Now: " + now);
        */

      var difference = Math.round((now - d) / 86400000, 0);

      return (
        d.getMonth() + 1 + "." + d.getDate() + "." + d.getFullYear() + " (" + difference + " days ago)"
      );
    },
    skippedFormatted: function() {
      if (this.show.skipped < 0) {
        return (
          "Skipped <strong>" + Math.abs(this.show.skipped) + "</strong> times."
        );
      } else if (this.show.skipped > 0) {
        return "Skipped to <strong>" + this.show.skipped + "</strong> times.";
      } else {
        return null;
      }
    },
    listContainerId: function() {
      return "list-" + this.show.listid;
    },
    hasAttributes: function() {
      return true;
      //return this.show.saveEpisodes || this.show.episodesExpire;
    }
  },
  methods: {
    watchShow: function(countDate) {
      let epNumber;

      if(this.show.saveEpisodes) {
        epNumber = prompt("Episode number?");

        if(epNumber === null) return;
      }

      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("tv/shows/watch/" + this.show.showid, {
          countDate: countDate,
          epNumber: epNumber
        })
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    skipShow: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("tv/shows/skip/" + this.show.showid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    makeCurrent: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("tv/shows/makeCurrent/" + this.show.showid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    activateDayed: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("tv/shows/makeAutoActive/" + this.show.showid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    deactivateDayed: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("tv/shows/makeAutoInactive/" + this.show.showid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    makeComplete: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("tv/shows/complete/" + this.show.showid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    resumeShow: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("tv/shows/resume/" + this.show.showid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    startNoDayShow: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("tv/shows/makeCurrent/" + this.show.showid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    endNoDayShow: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("tv/shows/endNoDayShow/" + this.show.showid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    handleFlagClick: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("tv/shows/flagToggle/" + this.show.showid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    handleDeleteClick: function() {
      if (confirm('Really delete "' + this.show.name + '"?')) {
        let self = this;
        this.loading = this.loadingColor;
        this.$http.delete("tv/shows/" + this.show.showid).then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
      }
    },
    scrollToListTop: function() {
      var myElement = document.getElementById(this.listContainerId);
      var topPos = myElement.offsetTop;

      EventBus.$emit("scroll-window-to-pos", { offset: topPos });
    }
  },
  mounted: function() {
    console.log("List Type: " + this.listtype);
  }
};
</script>
