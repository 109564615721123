<template>
  <ShowEditDeleteTiles :apiId="apiId" :name="name" :itemId="itemId" :tileType="tileType" :listTypeOptions="listTypeOptions" :dialogId="dialogId"></ShowEditDeleteTiles>
</template>

<script>
  import ShowEditDeleteTiles from "../../../components/display/EditDeleteTiles/ShowEditDeleteTiles.vue";

  export default {
    name: "movieArchiveGames",
    components: { ShowEditDeleteTiles },
    data: function() {
      return {
        apiId: "movies/archive",
        name: "Movies",
        itemId: "movieid",
        tileType: "MovieMovieTile",
        dialogId: "addMovie",
        listTypeOptions: [{text: "All", value: "all"}]
      }
    },
    mounted() {
      let self = this;
      let workingLists = [{text: "All", value: "all"}];

      this.$http.get("movies/list").then(function(response) {
        // self.lists = response.data.items;

        for(let i in response.data.items) {
          workingLists.push({ text: response.data.items[i].name, value: response.data.items[i].listid});
        }

        self.listTypeOptions = workingLists;
      });
    },
  };
</script>
