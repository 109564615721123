<template>
  <WatchHistoryTable></WatchHistoryTable>
</template>

<script>
  import WatchHistoryTable from "../../components/display/WatchHistoryTable.vue";

  export default {
    name: "showLists",
    components: { WatchHistoryTable },
    data: function() {
      return {
        apiId: "tv/lists",
        name: "List",
        itemId: "listid",
        tileType: "TVListTile",
        dialogId: "addList"
      }
    }
  };
</script>
