<template>
    <v-card class="mx-auto my-2">
      <v-card-title class="d-block headline text-center text-md-left">{{ item.name }}</v-card-title>
      <v-card-subtitle class="text-center text-md-left" v-if="item.active">Active</v-card-subtitle>
      <v-card-subtitle class="text-center text-md-left" v-else>Not Active</v-card-subtitle>
      <v-card-text class="text-center text-md-left">
        <div>${{ item.monthlyPrice }}</div>
        <!-- 
        <p class="display-1 text--primary">
          be•nev•o•lent
        </p>
        <p>adjective</p>
        <div class="text--primary">
          well meaning and kindly.<br>
          "a benevolent smile"
        </div>
        -->
      </v-card-text>
      <v-card-actions class="d-block text-center text-md-left">
        <v-btn
          text
          color="cardActionBtnColor"
          @click="editBtnClicked(item)"
        >
          Edit
        </v-btn>
        <v-btn
          text
          color="cardActionBtnColor"
          @click="deleteBtnClicked(item)"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
  export default {
    name: "ShowEditDeleteServicesTile",
    props: [ "item" ],
    data: function() {
        return {
        }
    },
    methods: {
        editBtnClicked: function(d) {
            this.$emit("editBtnClicked", d);
        },
        deleteBtnClicked: function(d) {
            this.$emit("deleteBtnClicked", d);
        }
    }
  };
</script>