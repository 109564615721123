<template>
  <v-card
    v-if="list.listType == 'dayed'"
    :id="containerId"
    flat
    :color="bgColor"
    class="mx-auto my-2"
    :loading="loading"
  >
    <v-card-title class="headline">
      <span class="clickable" @click="sectionTitleClick()">
        {{ list.listName }} 
        <v-icon v-if="sectionExpanded">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-chevron-up</v-icon>
      </span>
    </v-card-title>
    <v-card-subtitle>{{ shows.length }} Shows</v-card-subtitle>
    <v-card-text
      v-if="sectionExpanded"
      ><component :is="tileComponent"
        v-for="show in skipped"
        :key="show.showid + '_skipped'"
        :listtype="list.listType"
        :show="show"
        skipped="true"
        @listUpdated="listUpdated"
      ></component
    ></v-card-text>
    <v-card-text
      v-if="sectionExpanded"
      ><component :is="tileComponent"
        v-for="show in shows"
        :key="show.showid"
        :listtype="list.listType"
        :show="show"
        @listUpdated="listUpdated"
      ></component
    ></v-card-text>
  </v-card>
  <v-card
    v-else-if="list.listType == 'completed'"
    :id="containerId"
    flat
    :color="bgColor"
    class="mx-auto my-2"
    :loading="loading"
  >
    <v-card-title class="headline">
      <span class="clickable" @click="sectionTitleClick()">
        {{ list.listName }} 
        <v-icon v-if="sectionExpanded">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-chevron-up</v-icon>
      </span>
    </v-card-title>
    <v-card-subtitle>{{ shows.length }} Shows</v-card-subtitle>
    <v-card-text
      v-if="sectionExpanded"
      ><component :is="tileComponent"
        v-for="show in shows"
        :key="show.showid"
        :listtype="list.listType"
        :show="show"
        @listUpdated="listUpdated"
      ></component
    ></v-card-text>
  </v-card>
  <div v-else>
    <v-card
      :id="containerId"
      flat
      :color="bgColor"
      class="mx-auto my-2"
      :loading="loading"
    >
      <v-card-title class="headline">
        <span class="clickable" @click="sectionTitleClick()">
          {{ list.listName }}, Active 
          <v-icon v-if="sectionExpanded">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </span>
      </v-card-title>
      <v-card-subtitle
        >{{ shows.active ? shows.active.length : 0 }} Shows</v-card-subtitle
      >
      <v-card-text
        v-if="sectionExpanded"
        ><component :is="tileComponent"
          v-for="show in shows.active"
          :key="show.showid"
          :listtype="list.listType"
          nodaytype="active"
          :show="show"
          @listUpdated="listUpdated"
        ></component
      ></v-card-text>
    </v-card>
    <v-card
      :id="containerId"
      flat
      :color="bgColor"
      class="mx-auto my-2"
      :loading="loading"
    >
      <v-card-title class="headline">
        <span class="clickable" @click="sectionTitleClick()">
          {{ list.listName }}, Inactive 
          <v-icon v-if="sectionExpanded">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </span>
      </v-card-title>
      <v-card-subtitle
        >{{ shows.waiting ? shows.waiting.length : 0 }} Shows</v-card-subtitle
      >
      <v-card-text
        v-if="sectionExpanded"
        ><component :is="tileComponent"
          v-for="show in shows.waiting"
          :key="show.showid"
          :listtype="list.listType"
          nodaytype="waiting"
          :show="show"
          @listUpdated="listUpdated"
        ></component
      ></v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

import ShowTile from "./ShowTile.vue";
import MovieTile from "./MovieTile.vue";
import { EventBus } from "../../../events/EventBus.js";

export default {
  name: "ShowTileList",
  components: { ShowTile, MovieTile },
  props: ["list", "tileComponent", "api"],
  data: function() {
    return {
      shows: [],
      skipped: [],
      loading: false,
      baseColor: "",
      loadingBGColor: "grey lighten-1",
      loadingBarColor: "primary",
      sectionExpanded: true
    };
  },
  computed: {
    containerId: function() {
      return "list-" + this.list.listid;
    },
    bgColor: function() {
      if (!this.loading) return this.baseColor;

      return this.loadingBGColor;
    }
  },
  methods: {
    listUpdated: function() {
      console.log("Gets here 2", this.list);

      // Special handle for binge list - need to improve
      if (this.list.listid == "binge" || this.list.listid == "bingeactive") {
        this.loading = this.loadingBarColor;
        location.reload();
      } else {
        this.loading = this.loadingBarColor;
        let self = this;
        this.$http
          .get("tv/lists/schedule/list/" + this.list.listid)
          .then(function(response) {
            // handle success
            self.shows = response.data.list.shows;
            self.skipped = response.data.list.skipped;

            self.loading = false;
          });
      }
    },
    sectionTitleClick: function() {
      this.sectionExpanded = !this.sectionExpanded;
    }
  },
  mounted: function() {
    this.shows = this.list.shows;
    this.skipped = this.list.skipped;

    EventBus.$on(["addShowRefreshData"], data => {
      if (this.list.listid == data.listid) {
        console.log("Running refresh");
        this.listUpdated();
      }
    });
  },
  beforeDestroy() {
    EventBus.$off(["addShowRefreshData"]);
  }
};
</script>

<style>
  .clickable {
    cursor: pointer;
  }
</style>
