<template>
  <v-container fluid>
    <v-row>
      <!-- Name -->
      <v-col cols="12" md="12">
        <v-text-field
          label="Name*"
          v-model="value.name"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col 
        cols="6"
        md="3"
        v-for="option in allLists"
        :key="option.listid"
      >
        <v-checkbox
          :label="option.name"
          :value="option.listid"
          v-model="value.lists"
          direction="vertical"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "AddGameForm",
    props: {
      value: {
        type: Object,
        required: true
      },
      allLists: {
        type: Array
      }
    },
    watch: {
      value() {
        this.$emit("input", this.value);
      }
    },
  };
</script>
