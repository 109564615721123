<template>
  <ShowEditDeleteTiles :apiId="apiId" :name="name" :itemId="itemId" :tileType="tileType" :dialogId="dialogId"></ShowEditDeleteTiles>
</template>

<script>
  import ShowEditDeleteTiles from "../../components/display/EditDeleteTiles/ShowEditDeleteTiles.vue";

  export default {
    name: "movieLists",
    components: { ShowEditDeleteTiles },
    data: function() {
      return {
        apiId: "movies/list",
        name: "List",
        itemId: "listid",
        tileType: "MovieListTile",
        dialogId: "addMovieList"
      }
    }
  };
</script>
