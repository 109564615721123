<template>
  <v-card class="my-2" :color="listBGColor" :loading="loading">
    <v-container fluid>
      <v-row align="end" no-gutters>
        <v-col class="pa-2" cols="12" md="6">
          <h4 class="text-center">{{ data.listName }} ({{ data.meta.item_count }})</h4>
          
          <Show
            v-for="flag in data.flagged"
            :key="flag.showId + 'flagged'"
            :data="flag"
            listSet="flagged"
            mini
            @summaryUpdated="$emit('summaryUpdated')"></Show>

          <Show
            v-for="skip in data.skipped"
            :key="skip.showId + 'skipped'"
            :data="skip"
            listSet="skipped"
            mini
            @summaryUpdated="$emit('summaryUpdated')"></Show>
          <Show
            v-for="active in data.active"
            :key="active.showId"
            :data="active"
            listSet="active"
            @summaryUpdated="$emit('summaryUpdated')"
          ></Show>
          <h3 v-if="!data.active" class="text-center">(nothing active)</h3>
        </v-col>
        <v-col class="pa-2" cols="12" md="6">
          <div v-if="data.history.length > 0">
            <apexchart height="150" type="line" :options="chartOptions" :series="series"></apexchart>
            <div class="chart-details">
              <div class="chart-average"><h6>Average</h6>{{ totalAverage }} days</div>
              <div class="chart-runningavg"><h6>Running Average</h6>{{ runningAverage }} days</div>
              <div class="chart-last"><h6>Last</h6>{{ lastHistory }} days</div>
              <div class="chart-total"><h6>Total</h6>{{ totalHistory }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import Show from "../lists/SingleListSummaryShow.vue";

  export default {
    name: "SingleListSummary",
    components: { Show },
    props: ["data", "loading"],
    computed: {
      totalAverage: function() {
        return this.convertTime(this.data.history_summary.avg);
      },
      runningAverage: function() {
        return this.convertTime(this.data.history_summary.runningavg);
      },
      lastHistory: function() {
        return Math.round(this.data.history_summary.last);
      },
      totalHistory: function() {
        return this.data.history.length;
      },
      listBGColor: function() {
        if(this.loading)  return this.loadingListBGColor;

        return this.baseListBGColor;
      }
    },
    methods: {
      convertTime: function(d) {
        return Math.round(d / (60*60*24) * 10) / 10;
      },
    },
    data: function() {
      return {
        darkMode: true || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches),
        baseListBGColor: "",
        loadingListBGColor: "grey lighten-1",
        chartOptions: {
          chart: {
            id: 'history',
            toolbar: {
              show: false
            }
          },
          grid: {
            show: false
          },
          tooltip: {
            enabled: true
          },
          xaxis: {
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          yaxis: [{
            labels: {
              show: false
            },
            min: this.convertTime(this.data.history_summary.minShowDiff),
            max: this.convertTime(this.data.history_summary.maxShowDiff)
          },
          {
            labels: {
              show: false
            },
            min: this.convertTime(this.data.history_summary.minShowDiff),
            max: this.convertTime(this.data.history_summary.maxShowDiff)
          },
          {
            opposite: true,
            labels: {
              show: false
            },
            min: this.data.history_summary.minShowCount,
            max: this.data.history_summary.maxShowCount
          }],
          stroke: {
            width: [ 1, 2, 1 ]
          }
        },
        series: [{
          name: 'Watch History',
          data: this.data.history
        },
        {
          name: 'Trendline',
          data: this.data.history_summary.trendline,
          color: (true || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) ? "grey" : "black"
        },
        {
          name: 'Items On List',
          data: this.data.items_count_history,
        }]
      };
    }
  };
</script>

<style scoped>
.chart-details {
  display: grid;
  grid-template-areas:
            'average runningavg last total';
  text-align: center;
}

.chart-details .chart-average {
  grid-area: average;
}

.chart-details .chart-runningavg {
  grid-area: runningavg;
}

.chart-details .chart-last {
  grid-area: last;
}

.chart-details .chart-total {
  grid-area: total;
}
</style>