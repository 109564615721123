<template>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title color="modalTitleBar" class="headline" primary-title>{{ headlineText }}</v-card-title>
  
        <v-card-text>
          <v-card v-if="!isValid" color="error" class="ma-2"><v-card-text><h3 class="text-center white--text">Please fill in all required fields.</h3></v-card-text></v-card>
          <v-card v-if="errorMsg" color="error" class="ma-2"><v-card-text><h3 class="text-center white--text">{{ errorMsg }}</h3></v-card-text></v-card>
          <AddListForm v-model="form"></AddListForm>
        </v-card-text>
  
        <v-divider></v-divider>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="cancel()">Cancel</v-btn>
          <v-btn color="primary" text @click="formSubmitted()">{{ headlineText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
    import axios from "axios";
  
    import AddListForm from "../../forms/movies/AddList.vue";
    import { EventBus } from "../../../events/EventBus.js";
  
    export default {
      name: "AddMovieListDialog",
      components: { AddListForm },
      computed: {
        headlineText: function() {
          return this.editMode ? "Edit " + this.title : "Add " + this.title;
        }
      },
      data: function() {
        return {
          id: "addMovieList",
          title: "List",
          editMode: false,
          dialog: false,
          editingId: null,
          form: {
            name: ""
          },
          validation: {
            name: { required: true }
          },
          isValid: true,
          errorMsg: false,
          sentForm: null,
          returnCallback: null
        };
      },
      methods: {
        validateForm: function() {
          this.isValid = true;
  
          for(let v in this.validation) {
            if(this.validation[v].required) {
              console.log("Valid?", v, this.form[v], (this.form[v] === null), (this.form[v] === ""));
  
              if(this.form[v] === null || this.form[v] === "") {
                this.isValid = false;
                break;
              }
            }
          }
        },
        formSubmitted: function() {
          //Validate
          this.validateForm();
          if(!this.isValid)   return;
  
          if(this.returnCallback) {
            console.log("Callback");
            EventBus.$emit(this.returnCallback, {formData: this.form });
          }
          // Default
          else {
            console.log("No callback");
            let self = this;
            this.$http.post("movies/list/upsert", {id: this.editingId, data: this.form}).then(function(response) {
  
              if(response.data.success) {
                self.dialog = false;
                EventBus.$emit(self.id + "RefreshData");
              }
              else {
                self.errorMsg = response.data.msg;
              }
            });
          }
        },
        cancel() {
          this.dialog = false;
        }
      },
      mounted: function() {
        EventBus.$on("open-dialog", data => {
          console.log("Looking from here", data.id, this.id);
          if (data.id == this.id) {
  
            if(data.return) {
              this.returnCallback = data.return;
            }
            else {
              this.returnCallback = null;
            }
  
            if(data.editingId) {
              this.editingId = data.editingId;
            }
            else {
              this.editingId = null;
            }
  
            // If I sent in form data (editing show)
            if(data.data) {
              this.editMode = true;
              this.sentForm = data.data;
  
              this.form.name = this.sentForm.name;
            }
            else {
              this.editMode = false;
              this.sentForm = null;
  
              this.form.name = "";
            }
  
            this.dialog = true;
          }
        });
  
        EventBus.$on("close-dialog", data => {
          if (data.id == this.id) {
            this.dialog = false;
          }
        });
      }
    };
  </script>
  