<!-- Save lists doesn't work-->
<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title color="modalTitleBar" class="headline" primary-title>{{ headlineText }}</v-card-title>

      <v-card-text>
        <v-card v-if="!isValid" color="error" class="ma-2"><v-card-text><h3 class="text-center white--text">Please fill in all required fields.</h3></v-card-text></v-card>
        <v-card v-if="errorMsg" color="error" class="ma-2"><v-card-text><h3 class="text-center white--text">{{ errorMsg }}</h3></v-card-text></v-card>
        <AddGameForm v-model="form" :allLists="allLists"></AddGameForm>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="cancel()">Cancel</v-btn>
        <v-btn color="primary" text @click="formSubmitted()">{{ headlineText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import AddGameForm from "../../forms/games/AddGame.vue";
  import { EventBus } from "../../../events/EventBus.js";

  export default {
    name: "AddGameDialog",
    components: { AddGameForm },
    computed: {
      headlineText: function() {
        return this.editMode ? "Edit " + this.title : "Add " + this.title;
      }
    },
    data: function() {
      return {
        id: "addGame",
        title: "Game",
        editMode: false,
        dialog: false,
        editingId: null,
        form: {
          name: "",
          lists: []
        },
        validation: {
          name: { required: true },
          lists: { required: false }
        },
        isValid: true,
        errorMsg: false,
        sentForm: null,
        returnCallback: null,
        allLists: []
      };
    },
    methods: {
      validateForm: function() {
        this.isValid = true;

        for(let v in this.validation) {
          console.log(v, this.form, this.form[v]);

          if(this.validation[v].required) {
            if(this.form[v] === null || this.form[v] === "") {
              this.isValid = false;
              break;
            }
          }
        }
      },
      formSubmitted: function() {
        //Validate
        this.validateForm();
        if(!this.isValid)   return;

        if(this.returnCallback) {
          EventBus.$emit(this.returnCallback, {formData: this.form });
        }
        // Default
        else {
          console.log("Or is upsert here");
          let self = this;
          this.$http.post("games/game/upsert", {id: this.sentForm ? this.sentForm.gameid : null, data: this.form}).then(function(response) {

            if(response.data.success) {
              self.dialog = false;
              console.log("Ready to emit - " + self.id + "RefreshData");
              // EventBus.$emit(self.id + "RefreshData", {listid: self.form.listid});
            }
            else {
              self.errorMsg = response.data.msg;
            }
          });
        }
      },
      cancel() {
        this.dialog = false;
      }
    },
    mounted: function() {
      EventBus.$on("open-dialog", data => {
        if (data.id == this.id) {
          if(data.return) {
            this.returnCallback = data.return;
          }
          else {
            this.returnCallback = null;
          }

          if(data.editingId) {
            this.editingId = data.editingId;
          }
          else {
            this.editingId = null;
          }

          // If I sent in form data (editing)
          if(data.data) {
            console.log("Sent form", data.data);

            this.editMode = true;
            this.sentForm = data.data;

            this.form.name = this.sentForm.name;
            // this.form.lists = this.sentForm.lists;
            this.form.lists = this.sentForm.lists.map(item => item.listid);
          }
          else if(!data.dataId) {
            this.editMode = false;
            this.sentForm = null;

            this.form.name = "";
            this.form.lists = [];
          }

          var self = this;
          this.$http.get("games/list").then(function(response) {
            if(response.data.success) {
              self.allLists = response.data.items;
            }

            self.dialog = true;
          });
        }
      });

      EventBus.$on("close-dialog", data => {
        if (data.id == this.id) {
          this.dialog = false;
        }
      });
    }
  };
</script>
