<template>
    <v-card class="mx-auto my-2">
      <v-card-title class="d-block headline text-center text-md-left">{{ item.name }}</v-card-title>
      <v-card-subtitle v-if="item.lists && (item.lists.length > 0)">
        <v-chip
          v-for="list in item.lists"
          :key="list.listid"
          x-small
          class="mx-1"
          color="success"
          text-color="white"
        >{{ list.name }}</v-chip>
      </v-card-subtitle>
      <v-card-actions class="d-block text-center text-md-left">
        <div>
          <v-btn
            v-if="!item.played"
            text
            color="cardActionBtnColor"
            @click="handlePlay(item)"
          >
            Play
          </v-btn>
          <v-btn
            v-else
            text
            color="cardActionBtnColor"
            @click="handleUnplay(item)"
          >
            Unplay
          </v-btn>
        </div>
        <div>
          <v-btn
            text
            color="cardActionBtnColor"
            @click="editBtnClicked(item)"
          >
            Edit
          </v-btn>
          <v-btn
            text
            color="cardActionBtnColor"
            @click="deleteBtnClicked(item)"
          >
            Delete
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
</template>

<script>
  export default {
    name: "ShowEditDeleteGameTile",
    props: [ "item" ],
    data: function() {
        return {
        }
    },
    methods: {
        editBtnClicked: function(d) {
            this.$emit("editBtnClicked", d);
        },
        deleteBtnClicked: function(d) {
            this.$emit("deleteBtnClicked", d);
        },
        handlePlay: function(d) {
          let self = this;
          this.$http
            .post("games/play/" + this.item.gameid)
            .then(function(response) {
              // handle success
              self.$emit("listUpdated");
              self.scrollToListTop();
            });
        },
        handleUnplay: function(d) {
          let self = this;
          this.$http
            .post("games/unplay/" + this.item.gameid)
            .then(function(response) {
              // handle success
              self.$emit("listUpdated");
              self.scrollToListTop();
            });
        }
    }
  };
</script>