<template>
  <div>
    <!-- <h1>Schedule!</h1> -->
    <v-tabs
      class="tabs"
      centered
      grow
      height="60px"
      v-model="activeTab"
    >
      <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact>
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
  //import WatchHistoryTable from "../components/display/WatchHistoryTable.vue";

  export default {
    name: "Settings",
    //components: { WatchHistoryTable },
    data: function() {
      return {
        activeTab: ``,
        tabs: [
          { id: 1, name: "General", route: `/settings` },
          { id: 2, name: "Auto Services", route: `/settings/autoServices` }
        ]
      }
    }
  };
</script>
