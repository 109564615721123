<template>
  <ShowEditDeleteTiles :apiId="apiId" :name="name" :itemId="itemId" :tileType="tileType" :dialogId="dialogId"></ShowEditDeleteTiles>
</template>

<script>
  import ShowEditDeleteTiles from "../../components/display/EditDeleteTiles/ShowEditDeleteTiles.vue";

  export default {
    name: "showLists",
    components: { ShowEditDeleteTiles },
    data: function() {
      return {
        apiId: "services",
        name: "Services",
        itemId: "serviceid",
        tileType: "ServiceTile",
        dialogId: "addService"
      }
    }
  };
</script>
