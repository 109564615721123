<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title color="modalTitleBar" class="headline" primary-title>
        <span v-if="editMode">Edit Show</span>
        <span v-else>Add Show</span>
      </v-card-title>

      <v-card-text>
        <v-card v-if="!isValid" color="error" class="ma-2"><v-card-text><h3 class="text-center white--text">Please fill in all required fields.</h3></v-card-text></v-card>
        <v-card v-if="errorMsg" color="error" class="ma-2"><v-card-text><h3 class="text-center white--text">{{ errorMsg }}</h3></v-card-text></v-card>
        <AddShowForm v-model="form" @saveEpisodes="saveEpisodesValue" :lists="lists" :services="services"></AddShowForm>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="cancel()">Cancel</v-btn>
        <v-btn color="primary" text @click="formSubmitted()">
          <span v-if="editMode">Edit Show</span>
          <span v-else>Add Show</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import axios from "axios";

  import AddShowForm from "../forms/AddShow.vue";
  import { EventBus } from "../../events/EventBus.js";

  export default {
    name: "AddShowDialog",
    components: { AddShowForm },
    data: function() {
      return {
        id: "addShow",
        editMode: false,
        dialog: false,
        editingId: null,
        form: {
          name: "",
          listid: false,
          day: false,
          serviceid: false,
          skipped: false,
          hasBeenActive: false,
          saveEpisodes: false,
          saveEpisodesSeason: false,
          saveEpisodesEpisodeNumber: false,
          saveEpisodesEpisodeName: false,
          episodesExpire: false,
          notes: false
        },
        validation: {
          name: { required: true },
          listid: { required: true },
          serviceid: { required: false },
          skipped: { required: false },
          hasBeenActive: { required: false },
          saveEpisodes: { required: false },
          saveEpisodesSeason: { required: false },
          saveEpisodesEpisodeNumber: { required: false },
          saveEpisodesEpisodeName: { required: false },
          episodesExpire: { required: false },
          notes: { required: false }
        },
        isValid: true,
        errorMsg: false,
        lists: [],
        services: [],
        sentForm: null,
        returnCallback: null
      };
    },
    methods: {
      saveEpisodesValue: function(value) {
        console.log("Gets here", value);
      },
      validateForm: function() {
        this.isValid = true;

        for(let v in this.validation) {
          if(this.validation[v].required) {
            if(this.form[v] === null || this.form[v] === "") {
              this.isValid = false;
              break;
            }
          }
        }
      },
      formSubmitted: function() {
        //Validate
        this.errorMsg = false;
        this.validateForm();
        if(!this.isValid)   return;

        if(this.returnCallback) {
          EventBus.$emit(this.returnCallback, {formData: this.form });
        }
        // Default
        else {
          console.log("Or is upsert here");
          let self = this;
          this.$http.post("tv/shows/upsert", {id: this.sentForm ? this.sentForm.showid : null, data: this.form}).then(function(response) {

            if(response.data.success) {
              self.dialog = false;
              console.log("Ready to emit - " + self.id + "RefreshData");
              EventBus.$emit(self.id + "RefreshData", {listid: self.form.listid});
            }
            else {
              self.errorMsg = response.data.msg;
            }
          });
        }
      },
      cancel() {
        this.dialog = false;
      }
    },
    mounted: function() {
      EventBus.$on("open-dialog", data => {
        if (data.id == this.id) {

          if(data.return) {
            this.returnCallback = data.return;
          }
          else {
            this.returnCallback = null;
          }

          if(data.editingId) {
            this.editingId = data.editingId;
          }
          else {
            this.editingId = null;
          }

          let allNeededData = {lists: false, services: false, data: false};

          // If I sent in form data (editing show)
          if(data.data) {
            this.editMode = true;
            this.sentForm = data.data;

            this.form.name = this.sentForm.name;
            this.form.hasBeenActive = this.sentForm.hasBeenActive;
            this.form.skipped = this.sentForm.skipped;
            this.form.saveEpisodes = this.sentForm.saveEpisodes;
            this.form.saveEpisodesSeason = this.sentForm.saveEpisodesSeason;
            this.form.saveEpisodesEpisodeNumber = this.sentForm.saveEpisodesEpisodeNumber;
            this.form.saveEpisodesEpisodeName = this.sentForm.saveEpisodesEpisodeName;
            this.form.episodesExpire = this.sentForm.episodesExpire;
            this.form.notes = this.sentForm.notes;

            allNeededData.data = true;
          }
          else if(!data.dataId) {
            this.editMode = false;
            this.sentForm = null;

            this.form.name = "";
            this.form.hasBeenActive = false;
            this.form.skipped = 0;
            this.form.saveEpisodes = false;
            this.form.saveEpisodesSeason = "";
            this.form.saveEpisodesEpisodeNumber = "";
            this.form.saveEpisodesEpisodeName = "";
            this.form.episodesExpire = false;
            this.form.notes = "";

            this.form.listid = null;
            this.form.serviceid = null;
            this.form.day = null;

            allNeededData.data = true;
          }

          var self = this;

          // Need to get form data (editing show)
          if(data.dataId) {

          }

          // Need to get the possible lists from the db
          this.$http.get("tv/lists").then(function(response) {
            if(response.data.success) {
              self.lists = response.data.items;

              if(self.sentForm) {
                self.form.listid = self.sentForm.listid;
                self.form.day = self.sentForm.day;
              }

              allNeededData.lists = true;

              // Bad way to do this, definitely improve
              /*
              if(self.services) {
                self.dialog = true;
              }
              */

              let dialogReady = true;
              for(let i in allNeededData) {
                if(!allNeededData[i]) {
                  dialogReady = false;
                  break;
                }
              }

              if(dialogReady) {
                self.dialog = true;
              }
            }
          });

          // Need to get the possible services from the db
          this.$http.get("services").then(function(response) {
            if(response.data.success) {
              console.log("Got Services");
              self.services = response.data.items;

              if(self.sentForm) {
                console.log("Service", self.sentForm);
                self.form.serviceid = self.sentForm.serviceid;
              }

              allNeededData.services = true;

              // Bad way to do this, definitely improve
              /*
              if(self.lists) {
                self.dialog = true;
              }
              */

              let dialogReady = true;
              for(let i in allNeededData) {
                if(!allNeededData[i]) {
                  dialogReady = false;
                  break;
                }
              }

              if(dialogReady) {
                self.dialog = true;
              }
            }
          });
        }
      });

      EventBus.$on("close-dialog", data => {
        if (data.id == this.id) {
          this.dialog = false;
        }
      });
    }
  };
</script>
