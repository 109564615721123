<template>
  <v-container>
    <v-row>
      <!-- Name -->
      <v-col cols="12" md="6">
        <v-text-field
          label="Name *"
          v-model="value.name"
          required
          outlined
        ></v-text-field>
      </v-col>
      <!-- Which List -->
      <v-col cols="12" md="6">
        <v-select
          v-model="value.listid"
          :items="listOptions"
          label="List *"
          required
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="12">
        <v-select
          v-model="value.serviceid"
          :items="serviceOptions"
          label="Service *"
          required
          outlined
        ></v-select>
      </v-col>
      <!-- Which Day -->
      <v-col cols="12" md="12" v-if="dayedLists.includes(value.listid)">
        <v-select
          v-model="value.day"
          :items="dayOptions"
          label="Day *"
          required
          outlined
        ></v-select>
      </v-col>

      <!-- Skipped -->
      <v-col cols="12" md="12">
        <v-text-field
          label="Skipped"
          v-model="value.skipped"
          outlined
          hint="Positive = Skipped To; Negative = Skipped Past"
        ></v-text-field>
      </v-col>

      <!-- Other Attributes -->
      <v-col cols="12" md="12">
        <v-checkbox
          v-model="value.hasBeenActive"
          label="Has Been Active"
        ></v-checkbox>
      </v-col>

      <!-- Save Episodes -->
      <v-row class="mx-2">
        <v-col cols="12" md="3">
          <v-checkbox
            v-model="value.saveEpisodes"
            label="Save Episodes?"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="9" v-if="value.saveEpisodes">
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                label="Season"
                v-model="value.saveEpisodesSeason"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                label="Episode Number"
                v-model="value.saveEpisodesEpisodeNumber"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                label="Episode Name"
                v-model="value.saveEpisodesEpisodeName"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Other Attributes -->
      <v-col cols="12" md="12">
        <v-checkbox
          v-model="value.episodesExpire"
          label="Episodes Expire"
        ></v-checkbox>
      </v-col>

      <!-- Notes -->
      <v-col cols="12" md="12">
        <v-textarea
          v-model="value.notes"
          label="Notes"
          outlined
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "AddShowForm",
    props: {
      value: {
        type: Object,
        required: true
      },
      lists: Array,
      services: Array
    },
    data: function() {
      return {
        dayOptions: [{text: "Sunday", value: 0}, {text: "Monday", value: 1}, {text: "Tuesday", value: 2}, {text: "Wednesday", value: 3}, {text: "Thursday", value: 4}, {text: "Friday", value: 5}, {text: "Saturday", value: 6}],
      };
    },
    computed: {
      listOptions: function() {
        let items = [];

        for(let i in this.lists) {
          items.push( { text: this.lists[i].name, value: this.lists[i].listid} );
        }

        return items;
      },
      serviceOptions: function() {
        let items = [];

        for(let i in this.services) {
          items.push( { text: this.services[i].name, value: this.services[i].serviceid} );
        }

        return items;
      },
      dayedLists: function() {
        let items = [];

        for(let i in this.lists) {
          if(this.lists[i].dayed == 1) {
            items.push(this.lists[i].listid);
          }
        }

        return items;
      }
    },
    watch: {
      value() {
        this.$emit("input", this.value);
      }
    }
  };
</script>
