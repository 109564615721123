<template>
  <div>
    <h1>This is a placeholder for General</h1>
  </div>
</template>

<script>
  export default {
    name: "SettingsGeneral"
  };
</script>
