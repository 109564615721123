<template>
  <v-container class="bg-surface-variant">
    <v-row no-gutters>
      <v-col
        cols="6"
        sm="4"
      >
        <v-btn @click="runShowAuto()">Show Auto</v-btn>
      </v-col>
      <v-col
        cols="6"
        sm="4"
      >
        <v-btn @click="runRandom()">Random Show from</v-btn><v-select v-model="randomShowList" :items="lists"></v-select>
      </v-col>
    </v-row>

    <v-card class="mt-5">
      <div class="pa-5">
        <pre>{{ output }}</pre>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "tools",
    components: { },
    data: function() {
      return {
        output: null,
        lists: [],
        randomShowList: null
      }
    },
    methods: {
      runShowAuto: function() {
        let that = this;
        this.output = "";

        this.$http
          .get("/tv/tools/showAuto?cacheBreak=" + (Math.random()))
          .then(function(response) {
            that.output = response.data;
          });
      },
      runRandom: function() {
        let that = this;
        this.output = "";

        this.$http
          .get("/tv/tools/showRandom", { params: { list: this.randomShowList }})
          .then(function(response) {
            that.output = response.data;
          });
      }
    },
    mounted() {
      let self = this;
      let workingLists = [];

      this.$http.get("tv/lists").then(function(response) {
        self.lists = response.data.items;

        for(let i in response.data.items) {
          workingLists.push(response.data.items[i].listid);
        }

        self.lists = workingLists;
      });
    },
  };
</script>
