<template>
  <div>
    <!-- <h1>Schedule!</h1> -->
    <v-tabs
      class="tabs"
      centered
      grow
      height="60px"
      v-model="activeTab"
    >
      <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact>
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
  //import ShowEditDeleteTiles from "../components/display/EditDeleteTiles/ShowEditDeleteTiles.vue";

  export default {
    name: "schedule",
    components: {  },
    data: function() {
      return {
        activeTab: ``,
        tabs: [
          { id: 1, name: "Summary", route: `summary` },
          { id: 2, name: "Days (active)", route: `days-active` },
          { id: 2, name: "Days (all)", route: `days` },
          { id: 3, name: "Non-Days", route: `nondays` },
          { id: 3, name: "Archive", route: `archive` }
        ]
      }
    }
  };
</script>
