<template>
  <v-container>
    <v-row>
      <!-- Name -->
      <v-col cols="12" md="12">
        <v-text-field
          label="Name *"
          v-model="value.name"
          required
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "AddMovieListForm",
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    data: function() {
      return {
      };
    },
    watch: {
      value() {
        this.$emit("input", this.value);
      }
    }
  };
</script>
