<template>
  <v-container fluid>
    <v-row>
      <v-col cols="0" md="3" lg="4"></v-col>
      <v-col cols="12" md="6" lg="4">
        <!-- <component :is="tileType" v-for="item in items" :key="item.id" :item="item" @editBtnClicked="editBtnClicked" @deleteBtnClicked="deleteBtnClicked"></component> -->
        <ShowList v-for="item in items" :key="item.listid" :list="item" :tileComponent="tileComponent" :api="apiId"></ShowList>
      </v-col>
      <v-col cols="0" md="3" lg="4"></v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from "axios";
  import { EventBus } from "../../../events/EventBus.js";

  import ShowList from "./ShowList.vue";

  export default {
    name: "ShowTilesLists",
    components: { ShowList },
    props: [ "apiId", "tileComponent" ],
    data: function() {
      return {
        items: null
      };
    },
    methods: {
      getItems: function() {
        let self = this;
        this.$http.get(this.apiId).then(function(response) {
          // handle success
          self.items = response.data.lists;
        });
      },
    },
    mounted: function() {
      console.log("In lists");
      this.getItems();
    }
  };
</script>
