<template>
  <div>
    <v-tabs
      class="tabs"
      centered
      grow
      height="60px"
      v-model="activeTab"
    >
      <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact>
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "movies",
    components: {  },
    data: function() {
      return {
        activeTab: ``,
        tabs: [
          { id: 1, name: "Active", route: `active` },
          { id: 2, name: "Archive", route: `archive` }
        ]
      }
    }
  };
</script>
