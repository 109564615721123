<template>
  <v-card class="mx-auto my-2" :color="cardColor" :loading="loading">
    <div class="overline pt-1 mx-2">
      <v-row>
        <v-col cols="12" sm="8" class="text-center text-sm-right pt-0 pt-sm-3">
          <!-- <v-btn elevation="2" outlined x-small @click="watchShow(true)">Edit</v-btn> -->
          <v-btn
            v-if="!show.watched"
            elevation="2"
            outlined
            x-small
            :color="flaggedBtnColor"
            @click="handleFlagClick"
            ><v-icon>mdi-flag</v-icon></v-btn
          >
          <CardDialogButton
            text="Edit"
            dialogId="addMovie"
            :data="show"
            class="ml-1"
          ></CardDialogButton>
          <v-btn
            elevation="2"
            class="ml-1"
            outlined
            x-small
            @click="handleDeleteClick"
            >Delete</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <v-card-title class="headline d-block text-center text-md-left">{{
      show.name
    }}</v-card-title>
    <div v-if="hasAttributes" class="mx-2 text-center text-md-left">
      <v-chip
        v-if="show.currated"
        x-small
        class="mx-1"
        color="green"
        text-color="white"
        >Currated</v-chip
      >
    </div>
    <v-card-subtitle class="text-center text-md-left">
      <div v-if="show.date_watched" class="my-1">
        Watched {{ lastWatchFormatted }}
      </div>

      <v-card
        v-if="show.notes"
        elevation="0"
        class="mt-5 pa-1 indigo lighten-5"
        style="white-space: pre;"
        v-html="show.notes"
      ></v-card>
    </v-card-subtitle>
    <v-card-actions v-if="show.watched">
      <v-btn text @click="resumeMovie()">Un-watch</v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-btn text @click="watchMovie()">Watch</v-btn>
      
      <v-btn v-if="!show.currated" text @click="toggleCurrateMovie()">Currate</v-btn>
      <v-btn v-else text @click="toggleCurrateMovie()">Un-currate</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

import CardDialogButton from "../../buttons/CardDialogButton.vue";
import { EventBus } from "../../../events/EventBus.js";

export default {
  name: "MovieTile",
  components: { CardDialogButton },
  props: ["show"],
  data: function() {
    return {
      defaultColor: "",
      flaggedCardColor: "amber",
      loading: "false",
      defaultLoadingColor: "primary"
    };
  },
  computed: {
    cardColor: function() {
      if (this.show.flagged) {
        return this.flaggedCardColor;
      }

      return this.defaultColor;
    },
    loadingColor: function() {
      return this.defaultLoadingColor;
    },
    flaggedBtnColor: function() {
      return this.show.flagged ? "flagBtnColor" : "";
    },
    listContainerId: function() {
      return "list-" + this.show.listid;
    },
    hasAttributes: function() {
      return this.show.currated;
    },
    lastWatchFormatted: function() {
      var d = new Date(this.show.date_watched + " UTC+0000"); // Server sends/stores in UTC

      return (
        d.getMonth() + 1 + "." + d.getDate() + "." + d.getFullYear()
      );
    },
  },
  methods: {
    watchMovie: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("movies/watch/" + this.show.movieid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    resumeMovie: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("movies/resume/" + this.show.movieid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    toggleCurrateMovie: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("movies/currateToggle/" + this.show.movieid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    handleFlagClick: function() {
      let self = this;
      this.loading = this.loadingColor;
      this.$http
        .post("movies/flagToggle/" + this.show.movieid)
        .then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
    },
    handleDeleteClick: function() {
      if (confirm('Really delete "' + this.show.name + '"?')) {
        let self = this;
        this.loading = this.loadingColor;

        this.$http.delete("movies/" + this.show.movieid).then(function(response) {
          // handle success
          self.loading = false;
          self.$emit("listUpdated");
          self.scrollToListTop();
        });
      }
    },
    scrollToListTop: function() {
      var myElement = document.getElementById(this.listContainerId);
      var topPos = myElement.offsetTop;

      EventBus.$emit("scroll-window-to-pos", { offset: topPos });
    }
  }
};
</script>
