import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";

// TV
import TVSchedule from "../views/TV/Schedule.vue";
import TVScheduleSummaryTab from "../views/TV/Schedule/SummaryTab";
import TVScheduleDaysActiveTab from "../views/TV/Schedule/DaysActiveTab";
import TVScheduleDaysTab from "../views/TV/Schedule/DaysTab";
import TVScheduleNonDaysTab from "../views/TV/Schedule/NonDaysTab";
import TVScheduleArchiveTab from "../views/TV/Schedule/ArchiveTab";
import TVShowLists from "../views/TV/ShowLists";
import TVShowServices from "../views/TV/ShowServices";
import TVTools from "../views/TV/Tools";
import TVWatchHistory from "../views/TV/WatchHistory";

// Movies
import MoviesMovies from "../views/Movies/Movies";
import MoviesActiveTab from "../views/Movies/Movies/ActiveTab";
import MoviesArchiveTab from "../views/Movies/Movies/ArchiveTab";
import MoviesLists from "../views/Movies/Lists";
import MoviesTools from "../views/Movies/Tools";
// import Movies from "../views/Movies/Movies.vue";
// import MoviesSummaryTab from "../views/Movies/List/SummaryTab";
// import MoviesToWatchTab from "../views/Movies/List/ToWatchTab";
// import MoviesCurratedListTab from "../views/Movies/List/CurratedListTab";
// import MoviesArchiveTab from "../views/Movies/List/ArchiveTab";

// Games
import GamesGames from "../views/Games/Games";
import GamesActiveTab from "../views/Games/Games/ActiveTab";
import GamesArchiveTab from "../views/Games/Games/ArchiveTab";
import GamesLists from "../views/Games/Lists";
import GamesTools from "../views/Games/Tools";

// Settings
import Settings from "../views/Settings";
import SettingsGeneral from "../views/Settings/General";
import SettingsAutoServices from "../views/Settings/AutoServices";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
      path: "/tv",
      name: "TV",
      component: { render: (h) => h('router-view') }, // Using a functional component to render children
      children: [
        {
          path: "schedule",
          name: "Schedule",
          component: TVSchedule,
          children: [
            {
              path: "", //selected tab by default
              alias: ['summary'],
              name: "scheduleSummaryTab",
              component: TVScheduleSummaryTab
            },
            {
              path: "days-active",
              component: TVScheduleDaysActiveTab
            },
            {
              path: "days",
              component: TVScheduleDaysTab
            },
            {
              path: "nondays",
              component: TVScheduleNonDaysTab
            },
            {
              path: "archive",
              component: TVScheduleArchiveTab
            }
          ]
        },
        {
          path: "lists",
          name: "showLists",
          component: TVShowLists
        },
        {
          path: "services",
          name: "showServices",
          component: TVShowServices
        },
        {
          path: "tools",
          name: "tools",
          component: TVTools
        },
        {
          path: "watchhistory",
          name: "watchHistory",
          component: TVWatchHistory
        },
      ]
    },
    {
      path: "/movies",
      name: "Movies",
      component: { render: (h) => h('router-view') }, // Using a functional component to render children
      children: [
        {
          path: "movies",
          name: "movieMovies",
          component: MoviesMovies,
          children: [
            {
              path: "",
              alias: [ "active" ],
              name: "MoviesActiveTab",
              component: MoviesActiveTab
            },
            {
              path: "archive",
              component: MoviesArchiveTab
            }
          ]
        },
        {
          path: "lists",
          name: "moviesLists",
          component: MoviesLists
        },
        {
          path: "tools",
          name: "movieTools",
          component: MoviesTools
        }
      ]
    },
    // {
    //   path: "/movies",
    //   name: "Movies",
    //   component: { render: (h) => h('router-view') }, // Using a functional component to render children
    //   children: [
    //     {
    //       path: "list",
    //       name: "Movie List",
    //       component: Movies,
    //       children: [
    //         {
    //           path: "", //selected tab by default
    //           alias: ['summary'],
    //           name: "MoviesSummaryTab",
    //           component: MoviesSummaryTab
    //         },
    //         {
    //           path: "to-watch",
    //           component: MoviesToWatchTab
    //         },
    //         {
    //           path: "currated-list",
    //           component: MoviesCurratedListTab
    //         },
    //         {
    //           path: "archive",
    //           component: MoviesArchiveTab
    //         },
    //       ]
    //     }
    //   ]
    // },
    {
      path: "/games",
      name: "Games",
      component: { render: (h) => h('router-view') }, // Using a functional component to render children
      children: [
        {
          path: "games",
          name: "gameGames",
          component: GamesGames,
          children: [
            {
              path: "",
              alias: [ "active" ],
              name: "GameActiveTab",
              component: GamesActiveTab
            },
            {
              path: "archive",
              component: GamesArchiveTab
            }
          ]
        },
        {
          path: "lists",
          name: "gameLists",
          component: GamesLists
        },
        {
          path: "tools",
          name: "gameTools",
          component: GamesTools
        }
      ]
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      children: [
        {
          path: "", //selected tab by default
          name: "general",
          component: SettingsGeneral
        },
        {
          path: "autoServices",
          component: SettingsAutoServices
        }
      ]
    }
];

const router = new VueRouter({
    routes: routes
});

export default router;
