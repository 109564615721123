<template>
  <ShowEditDeleteTiles :apiId="apiId" :name="name" :itemId="itemId" :tileType="tileType" :dialogId="dialogId"></ShowEditDeleteTiles>
</template>

<script>
  import ShowEditDeleteTiles from "../../components/display/EditDeleteTiles/ShowEditDeleteTiles.vue";

  export default {
    name: "gameLists",
    components: { ShowEditDeleteTiles },
    data: function() {
      return {
        apiId: "games/list",
        name: "List",
        itemId: "listid",
        tileType: "GameListTile",
        dialogId: "addGameList"
      }
    }
  };
</script>
