<template>
  <ShowLists :apiId="apiId" :tileComponent="tileComponent"></ShowLists>
</template>

<script>
  import ShowLists from "../../../components/display/ShowTiles/ShowLists.vue";

  export default {
    name: "ScheduleBingeTab",
    components: { ShowLists },
    data: function() {
      return {
        apiId: "tv/lists/schedule/schedule-archive",
        tileComponent: "ShowTile"
      }
    }
  };
</script>
